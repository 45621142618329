import React from 'react';
import './aboutUs.css';

function AboutUs() {
  return (
    <section className="about-us" data-testid="about-us">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Suspendisse sed nisi lacus sed viverra. Duis ultricies lacus
        sed turpis tincidunt id aliquet risus feugiat. Purus semper eget duis at tellus. Augue lacus
        viverra vitae congue eu consequat ac. Pulvinar pellentesque habitant morbi tristique. Hac
        habitasse platea dictumst vestibulum rhoncus est. Diam phasellus vestibulum lorem sed. Eros
        donec ac odio tempor orci. Ut pharetra sit amet aliquam. Massa eget egestas purus viverra
        accumsan in nisl nisi scelerisque.
      </p>
      <p>
        Ac auctor augue mauris augue neque gravida in fermentum et. Enim facilisis gravida neque
        convallis a cras semper auctor. Ac tortor vitae purus faucibus ornare suspendisse sed nisi.
        Dui accumsan sit amet nulla facilisi morbi tempus iaculis. Velit laoreet id donec ultrices
        tincidunt arcu non. Id eu nisl nunc mi ipsum faucibus. Molestie ac feugiat sed lectus
        vestibulum mattis. Scelerisque purus semper eget duis at tellus at urna. Magna eget est
        lorem ipsum dolor sit amet consectetur adipiscing. Metus vulputate eu scelerisque felis
        imperdiet proin. Sed augue lacus viverra vitae congue eu consequat ac. Tortor aliquam nulla
        facilisi cras fermentum odio. Vitae elementum curabitur vitae nunc. Nec tincidunt praesent
        semper feugiat nibh. Arcu dictum varius duis at. Pellentesque massa placerat duis ultricies
        lacus sed turpis. Justo laoreet sit amet cursus sit amet dictum sit. Lacus suspendisse
        faucibus interdum posuere lorem ipsum dolor sit. Adipiscing elit pellentesque habitant morbi
        tristique senectus. Id semper risus in hendrerit gravida.
      </p>
    </section>
  );
}

export default AboutUs;
